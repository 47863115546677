import React from 'react';
import Style from './About.module.scss';
import Terminal from "./Terminal";
import { Box } from "@mui/material";
import { info } from "../../info/Info";

export default function About() {
    const firstName = info.firstName.toLowerCase();

    function aboutMeText() {
        return (
            <>
                <p><span style={{ color: info.baseColor }}>{firstName}{info.lastName.toLowerCase()} $</span> cat about{firstName} </p>
                <p><span style={{ color: info.baseColor }}>about{firstName} <span className={Style.green}>(main)</span> $ </span>{info.bio}</p>
            </>
        );
    }

    function skillsText() {
        return (
            <>
                <p><span style={{ color: info.baseColor }}>{firstName}{info.lastName.toLowerCase()} $</span> cd skills/tools</p>
                <p><span style={{ color: info.baseColor }}>skills/tools <span className={Style.green}>(main)</span> $</span> ls</p>
                <p style={{ color: info.baseColor }}> Proficient In</p>
                <ul className={Style.skills}>
                    {info.skills.proficientWith.map((proficiency, index) => <li key={index}>{proficiency}</li>)}
                </ul>
                <p style={{ color: info.baseColor }}> Comfortable With</p>
                <ul className={Style.skills}>
                    {info.skills.exposedTo.map((skill, index) => <li key={index}>{skill}</li>)}
                </ul>
            </>
        );
    }

    function miscText() {
        const certificationsBySchool = {};
    
        // Group certifications by school
        info.certifications.forEach((cert) => {
            if (!certificationsBySchool[cert.school]) {
                certificationsBySchool[cert.school] = [];
            }
            certificationsBySchool[cert.school].push(cert);
        });
    
        return (
            <>
                {Object.entries(certificationsBySchool).map(([school, certifications], schoolIndex) => (
                    <div key={schoolIndex}>
                        <p><span style={{ color: info.baseColor }}>{firstName}{info.lastName.toLowerCase()} $</span> cd certifications/{school}</p>
                        <p><span style={{ color: info.baseColor }}>certifications/{school} <span className={Style.green}>(main)</span> $</span> ls</p>
                        <ul>
                            {certifications.map((cert, index) => (
                                <li key={index}>
                                    <Box component={'span'} mr={'1rem'}>{cert.emoji}</Box>
                                    {cert.label}
                                </li>
                            ))}
                        </ul>
                        {/* Add spacing before the next cd */}
                        {schoolIndex < Object.entries(certificationsBySchool).length - 1 && <p>&nbsp;</p>}
                    </div>
                ))}
            </>
        );
    }
    
    

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal text={aboutMeText()} />
            <Terminal text={skillsText()} />
            <Terminal text={miscText()} />
        </Box>
    );
}
