import React, { useState } from 'react';
import { Box, TextField, Button, Snackbar, Alert, CircularProgress } from "@mui/material";
import Terminal from '../about/Terminal';
import Style from "../about/About.module.scss"; // Assuming styles are similar to About page

export default function Contact() {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [open, setOpen] = useState(false);
    const [alertType, setAlertType] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = {
            name,
            email,
            message
        };

        setLoading(true);

        try {
            const response = await fetch('https://us-central1-lifequest-29fa1.cloudfunctions.net/sendLnKEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setAlertType('success');
                setAlertMessage('Message sent successfully!');
                setName('');
                setEmail('');
                setMessage('');
            } else {
                setAlertType('error');
                setAlertMessage('Failed to send message.');
            }
        } catch (error) {
            console.error('Error:', error);
            setAlertType('error');
            setAlertMessage('An error occurred while sending the message.');
        } finally {
            setLoading(false);
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'3rem'}>
            <Terminal 
                text={
                    <Box 
                        component="form" 
                        display="flex" 
                        flexDirection="column" 
                        gap="1rem" 
                        p="1rem"
                        sx={{ borderRadius: 1, backgroundColor: '#27242f' }} // Terminal background
                        onSubmit={handleSubmit}
                    >
                        <TextField
                            label="Name"
                            variant="outlined"
                            fullWidth
                            required
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            sx={{ 
                                backgroundColor: '#1e1e1e', // Terminal-like background color
                                color: '#c5c8c6', // Text color similar to terminal text
                                '& .MuiInputLabel-root': { color: '#c5c8c6' } // Label color
                            }}
                        />
                        <TextField
                            label="Email Address"
                            variant="outlined"
                            type="email"
                            fullWidth
                            required
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            sx={{ 
                                backgroundColor: '#1e1e1e', 
                                color: '#c5c8c6',
                                '& .MuiInputLabel-root': { color: '#c5c8c6' }
                            }}
                        />
                        <TextField
                            label="Message"
                            variant="outlined"
                            multiline
                            rows={4}
                            fullWidth
                            required
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            sx={{ 
                                backgroundColor: '#1e1e1e', 
                                color: '#c5c8c6',
                                '& .MuiInputLabel-root': { color: '#c5c8c6' }
                            }}
                        />
                        <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            sx={{ backgroundColor: '#3a3a3a', color: '#c5c8c6' }} // Button styling
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Send'}
                        </Button>
                    </Box>
                }
            />
            <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                sx={{ width: 'auto' }}
            >
                <Alert onClose={handleClose} severity={alertType} sx={{ width: '100%' }}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
}
