import self from "../img/self.png"
import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];

export const info = {
    firstName: "Lee",
    lastName: "Kaplan",
    initials: "lk",
    position: "a Full Stack Web Developer and IT Consultant",
    selfPortrait: self,
    gradient: `-webkit-linear-gradient(135deg, ${colors})`,
    baseColor: colors[0],
    miniBio: [
        {
            emoji: '🧃',
            text: 'fueled by RedBull'
        },
        {
            emoji: '🌎',
            text: 'based in South Africa'
        },
        {
            emoji: "📧",
            text: 'lee@lnkcommunciations.co.za'
        }
    ],
    socials: [
        {
            link: "https://www.instagram.com/that.guy.lee/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/that-guy-lee",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/lee-kaplan/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        },
        {
            link: "https://wa.me/27843900046",
            icon: 'fa fa-whatsapp',
            label: 'whatsapp'
        },
    ],
    

    bio: "Hi, I'm Lee, a 22-year-old based in South Africa with a background in Fullstack Web and Software Development, Data Science, Machine Learning, and Cybersecurity. I completed comprehensive training through HyperionDev, PluralSight and Google certifications and now have a few years of experience. My passion for technology, coupled with a commitment to continuous learning, fuels my drive to contribute to innovative projects. With a strong collaborative spirit and effective communication skills, I am excited about applying my skills to make meaningful contributions in the tech industry.",
    skills:
    {
        proficientWith: ['JavaScript', 'ReactJS', 'Git', 'GitHub', 'HTML5', 'CSS3', 'Wordpress', 'SQL Server', 'NodeJS', 'Multiple UI Kits', 'Firebase'],
        exposedTo: ['Python', '.NET Core', 'VB.Net', 'MySQL', 'Docker', 'OpenAI API', 'React Native', 'Tailwind CSS',]

    },
    certifications: [
        {
            label: 'Immersive Full Stack Web Developer and Software Engineer Bootcamp',
            emoji: '👨‍🎓',
            school: 'HyperionDev'
        },
        {
            label: 'Data Science Bootcamp',
            emoji: '👨‍🎓',
            school: 'HyperionDev'
        },
        {
            label: 'Combining and Filtering Data with T-SQL 2019',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'T-SQL Data Manipulation Playbook',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Programming SQL Server Database Stored',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Programming SQL Server Database Triggers',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Managing SQL Server Database Concurrency',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Querying Data Using T-SQL',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Designing and Implementing SQL Server Tables',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'C# 9 Getting Started',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'C# 9 The Big Picture',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Introduction to the C# 9 Type System',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Controlling Program Flow in C# 9',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'C# 8 Interfaces',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Working with Arrays and Collections in C# 9',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'C# Extension Methods',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'C# 9 Language-Integrated Query (LINQ)',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'TypeScript 4 Getting Started',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Creating Object-oriented TypeScript Code',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'TypeScript The Big Picture',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Creating Asynchronous TypeScript Code',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Configuring, Compiling, and Debugging',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Using TypeScript 4 Modules',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Creating and Using TypeScript 3 Decorators',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Blazor 5 The Big Picture',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Securing Blazor Client-side Applications',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Securing Blazor Server-side Applications',
            emoji: '👨‍🎓',
            school: 'PluralSight'
        },
        {
            label: 'Foundations of Cybersecurity',
            emoji: '👨‍🎓',
            school: 'Google via Coursera'
        },
        {
            label: 'Manage Security Risks',
            emoji: '👨‍🎓',
            school: 'Google via Coursera'
        },
        {
            label: 'Networks and Network Security',
            emoji: '👨‍🎓',
            school: 'Google via Coursera'
        },




    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "PetFinder",
            live: "https://paytonpierce.dev",
            image: mock1
        },
        {
            title: "Basic Memory Game",
            live: "https://github.com/that-guy-lee/MemoryGame",
            image: mock2
        },
        {
            title: "Budgeting Web App",
            live: "https://github.com/that-guy-lee/MemoryGame",
            image: mock3
        }
    ]
}